<template>
  <img class="profile-pic-bg" :src="profilePic" :alt="user.displayName">
</template>

<script>
import {mapState} from "vuex";
import { createAvatar } from '@dicebear/core';
import { micah } from '@dicebear/collection';

export default {
  name: "ProfilePicture",
  computed: {
    ...mapState(['user']),
    profilePic() {
      const avatar = createAvatar(micah, {
        size: 128,
        seed: this.user.displayName
      });
      return avatar.toDataUriSync();
    }
  },
}
</script>

<style scoped>
.profile-pic-bg {
  background: var(--background-color);
}
</style>
