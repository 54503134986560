<template>
  <div class="home">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-content">

          <!-- ***** Banner Start ***** -->
          <div class="main-banner-home mb-4 bg-transparent">
            <div class="row">
              <div class="col-lg-8 order-1 order-lg-0">
                <div class="header-text">
                  <h1 class="mt-3 mb-2">AI-Powered QR code</h1>
                  <p class="col-lg-9 mb-4">
                    Say goodbye to boring QR codes!✨ Spice up your QR codes with a touch of creativity and embed them with stunning images! 🎨 Let your QR codes stand out from the crowd and make a lasting impression. Get ready to embrace the QR code revolution! 😎🚀
                  </p>
                  <div class="main-button mb-2">
                    <router-link to="/generate">Generate QR code Now</router-link>
                  </div>
                  <div>
                    <a href="https://www.producthunt.com/posts/ai-powered-qr-code?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ai&#0045;powered&#0045;qr&#0045;code"
                       target="_blank">
                      <img
                          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=402582&theme=neutral"
                          alt="AI&#0045;Powered&#0032;QR&#0032;code - Spice&#0032;up&#0032;your&#0032;QR&#0032;codes&#0032;with&#0032;a&#0032;touch&#0032;of&#0032;creativity | Product Hunt"
                          style="width: 230px; height: 54px;" width="230" height="54"/>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 order-0 order-lg-1">
                <div class="carousel-img">
                  <b-carousel no-animation :indicators="true" :interval="2000">
                    <b-carousel-slide v-for="(image, index) in images" :key="index" :img-src="image" img-alt="AI generated QR code"></b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
            </div>
          </div>
          <!-- ***** Banner Start ***** -->

          <!-- ***** Example Section ***** -->
          <div class="mb-4">
<!--            <div class="heading-section">-->
<!--              <h4><em>Most Popular</em> Right Now</h4>-->
<!--            </div>-->
            <div class="row">
              <div class="col-6 col-md-4 col-lg-3 mb-3" v-for="(image, index) in images" :key="index">
                <router-link class="image-container carousel-img" to="/generate">
                  <img class="img-fluid" height="512px" width="512px" :src="image" :alt="'AI-Powered QR code example ' + index">
                  <div class="overlay">
                    <span class="overlay-text">Generate Now!</span>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-lg-12">
                <div class="main-button h-auto">
                  <router-link to="/showcase">View all creations</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- ***** Example Section ***** -->

          <!-- ***** How to Section ***** -->
          <div class="start-stream steps mb-4">
            <div class="main-banner p-4 p-md-5">
              <div class="heading-section my-0 text-start">
                <h4>How it Works?</h4>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 mb-4 mb-md-0">
                  <div class="item m-0">
                    <div class="icon">
                      <h1><span>#</span>1</h1>
                    </div>
                    <h4>Enter Your Destination</h4>
                    <p class="mb-4">
                      Direct us to your desired QR code destination - your website, digital menu, social media page, and beyond.
                    </p>
                    <div class="main-button mb-0">
                      <router-link to="/generate">Start Now</router-link>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 mb-4 mb-md-0">
                  <div class="item m-0">
                    <div class="icon">
                      <h1><span>#</span>2</h1>
                    </div>
                    <h4>Tell Us Your Style</h4>
                    <p>
                      Share your brand details. Our AI will create a custom QR code that matches your visual identity.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="item m-0">
                    <div class="icon">
                      <h1><span>#</span>3</h1>
                    </div>
                    <h4>Download and Share</h4>
                    <p>
                      We generate a custom QR code just for you. Simply download it and begin enhancing your brand's digital presence!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ***** How to Section ***** -->

          <!-- ***** Usecase Stream ***** -->
          <div class="start-stream mb-2">
            <div class="heading-section m-0 text-start">
              <h4><em>Real-World</em> Use Cases</h4>
            </div>
            <div class="row">
              <div class="col-md-4 col-lg-3 mb-4 d-flex" v-for="(item, index) in useCase" :key="index">
                <a href="#contact">
                  <div class="item border-0 flex-fill m-0">
                    <div class="icon">
                      <b-icon :icon="item.icon"/>
                    </div>
                    <h4>{{item.title}}</h4>
                    <p>{{item.description}}</p>
                  </div>
                </a>
              </div>
<!--              <div class="col-lg-12">-->
<!--                <div class="main-button">-->
<!--                  <router-link to="/generate">Generate QR Code Now</router-link>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
          <!-- ***** Usecase Stream ***** -->

<!--         <Library/>-->

          <div id="contact">
            <Contact/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Library from "../components/Library";
import QRImg1 from "../assets/images/codes/QR-Code-1.webp";
import QRImg2 from "../assets/images/codes/QR-Code-2.webp";
import QRImg3 from "../assets/images/codes/QR-Code-3.webp";
import QRImg4 from "../assets/images/codes/QR-Code-4.webp";
import QRImg5 from "../assets/images/codes/QR-Code-5.webp";
// import QRImg6 from "../assets/images/codes/QR-Code-6.webp";
// import QRImg7 from "../assets/images/codes/QR-Code-7.webp";
import QRImg8 from "../assets/images/codes/QR-Code-8.webp";
import QRImg9 from "../assets/images/codes/QR-Code-9.webp";
import QRImg10 from "../assets/images/codes/QR-Code-10.webp";
import QRImg11 from "../assets/images/codes/QR-Code-11.webp";
import QRImg12 from "../assets/images/codes/QR-Code-12.webp";
import QRImg13 from "../assets/images/codes/QR-Code-13.webp";
import Contact from "../components/Contact";

export default {
  name: 'Home',
  components: {Contact},
  // components: {Library},
  data() {
    return {
      QRImg1: QRImg1,
      QRImg2: QRImg2,
      QRImg3: QRImg3,
      QRImg4: QRImg4,
      QRImg5: QRImg5,
      // QRImg6: QRImg6,
      // QRImg7: QRImg7,
      QRImg8: QRImg8,
      QRImg9: QRImg9,
      QRImg10: QRImg10,
      QRImg11: QRImg11,
      QRImg12: QRImg12,
      QRImg13: QRImg13,
      images: [
        QRImg1,
        // QRImg2,
        QRImg3,
        QRImg4,
        QRImg5,
        QRImg13,
        QRImg10,
        // QRImg6,
        // QRImg7,
        // QRImg8,
        QRImg9,
        QRImg11,
        // QRImg12,
      ],
      useCase: [
        {
          title: "E-Commerce",
          description: "Optimize e-commerce experiences with QR codes seamlessly integrated into product images, enabling quick access to product details, reviews, or purchasing options.",
          icon: "cart-fill"
        },
        {
          title: "Online Advertising",
          description: "Enhance online ads with artistic QR codes, seamlessly integrating them into visuals to provide instant access to more information or promotional offers.",
          icon: "megaphone-fill"
        },
        {
          title: "Business Cards",
          description: "Create impressive business cards with embedded QR codes, seamlessly blending contact information with stylish designs to leave a lasting impression.",
          icon: "person-lines-fill"
        },
        {
          title: "Educational Resources",
          description: "Make educational resources interactive by embedding QR codes within study materials, providing students with easy access to supplementary content or quizzes.",
          icon: "book-fill"
        },
        {
          title: "Travel & Tourism",
          description: "Immerse travelers in destinations with QR codes integrated into travel visuals, offering access to travel guides, local recommendations, or virtual tours.",
          icon: "signpost2-fill"
        },
        {
          title: "Restaurant Menus",
          description: "Modernize restaurant menus with QR codes elegantly incorporated into the design, allowing diners to view menus, place orders, or access nutritional information.",
          icon: "file-text"
        },
        {
          title: "Museum Exhibits",
          description: "Enrich museum exhibits with QR codes subtly embedded within artwork or display panels, offering visitors interactive experiences and detailed information.",
          icon: "bank2"
        },
        {
          title: "Webinars & Online Courses",
          description: "Integrate QR codes into webinar or online course materials, allowing participants to effortlessly access additional resources or lecture materials.",
          icon: "play-btn-fill"
        },
        {
          title: "Corporate Events",
          description: "Elevate corporate events with QR codes discreetly embedded within event materials, providing attendees with event schedules, speaker bios, or networking profiles.",
          icon: "briefcase-fill"
        },
        {
          title: "Music Album Covers",
          description: "Enhance music album covers with QR codes that blend seamlessly into the design, giving fans direct access to song previews, lyrics, or exclusive content.",
          icon: "music-note-beamed"
        },
        {
          title: "Customer Reviews",
          description: "Simplify customer reviews with QR codes integrated into feedback forms or receipts, enabling customers to quickly share their experiences and opinions.",
          icon: "star-fill"
        },
        {
          title: "Link Trees",
          description: "Enhance your online presence with QR codes integrated into link trees, giving your audience easy access to your social media profiles, websites, or portfolio.",
          icon: "link45deg"
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-img {
  border-radius: 24px;
  overflow: hidden;
}
.image-container {
  position: relative;
  display: inline-block;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #05041ddb;
  backdrop-filter: blur(6px);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container:hover .overlay {
  opacity: 1;
}
.overlay-text {
  color: #fff;
  font-size: 30px;
  text-align: center;
  font-weight: bolder;
  text-decoration: underline;
}
.start-stream {
  .item {
    min-height: 445px;
    background: var(--panel-background-color);
    .icon {
      font-size: 60px;
      color: var(--main-color);
      h1 {
        font-size: 40px;
      }
      span {
        color: var(--main-dark-color);
      }
    }
  }
}
.steps {
  .item {
    min-height: 380px;
  }
}
.bg-transparent {
  background: transparent!important;
  border: 1px solid var(--main-dark-color);
}
</style>
