import Vue from 'vue'
import Vuex from 'vuex'
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  deleteDoc
} from "firebase/firestore";
import {browserLocalPersistence, getAuth, GoogleAuthProvider, setPersistence, signInWithPopup} from "firebase/auth";
import {getDownloadURL, getStorage, ref as storageRef} from "firebase/storage";
import Swal from 'sweetalert2';

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage();

const auth = getAuth();
setPersistence(auth, browserLocalPersistence).then();

const googleAuthProvider = new GoogleAuthProvider();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    analytics: analytics,
    userDetails: null,
    user: null,
    imageLibrary: [],
    totalImages: '-',
    unsubscribeImageLibrary: null,
    unsubscribeUser: null,
    showCase: [],
    showCasePageNumber: 1,
    generateData: {
      text: "",
      prompt: "Utopian amazon rainforest",
      selectedPercentage: 75,
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setUnsubscribeImageLibrary(state, payload)  {
      state.unsubscribeImageLibrary = payload;
    },
    setShowCase(state, payload) {
      state.showCase = payload;
    },
    setShowCasePageNumber(state, payload) {
      state.showCasePageNumber = payload;
    },
    setGenerateData(state, {prompt, text, selectedPercentage}) {
      state.generateData.text = text;
      state.generateData.prompt = prompt;
      state.generateData.selectedPercentage = selectedPercentage;
    }
  },
  actions: {
    async generateImage({ state, dispatch }, data) {
      if (!state.user) {
        try {
          await dispatch('loginWithGoogle');
          if (!state.user) {
            return null;
          }
        }
        catch (e) {
          console.error("error", e);
          return null;
        }
      }
      try  {
        const imageData = {
          text: data.text,
          prompt: data.prompt,
          integration: data.integration,
          theme: data.theme,
          themeId: data.themeId,
          options: data.options,
          userId: state.user.uid,
          createdAt: serverTimestamp()
        };
        if (data.shortId && data.originalLink) {
          imageData.shortId = data.shortId;
          imageData.originalLink = data.originalLink;
        }
        const newImageDocRef = await addDoc(collection(db, "images"), imageData)
        return newImageDocRef.id
      }
      catch (e) {
        console.error('Error:', e)
      }
      return null
    },
    async loginWithGoogle({commit, state, dispatch}) {
      try {

        const result = await signInWithPopup(auth, googleAuthProvider)
        const user = result.user
        commit('setUser', user)

        const collectionRef = collection(db, "users");
        const q = query(collectionRef, where("email", "==", user.email));

        state.unsubscribeUser = onSnapshot(q, async (querySnapshot) => {
          if (!querySnapshot.empty) {
            // console.log("Document with the email exists");
            let user;
            querySnapshot.forEach((doc) => {
              user = doc.data();
            });
            state.userDetails = user;
            // console.log("state.userDetails", state.userDetails);
          } else {
            // console.log("Document with the email does not exist");
            await addDoc(collectionRef, {
              uid: user.uid,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              phoneNumber: user.phoneNumber,
              photoURL: user.photoURL,
              providerId: user.providerId,
              tenantId: user.tenantId,
              tokens: 10,
            });
            // console.log("Document added successfully!");
          }

        }, (error) => {
          console.log("Error getting documents: ", error);
        });

        dispatch('trackLoginEvent');
      }
      catch (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("errorCode:", errorCode, "errorMessage:", errorMessage)
      }
    },
    async getImageUrl(store, imagePath) {
      try {
        const imageRef = storageRef(storage, imagePath);
        return await getDownloadURL(imageRef);
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    },
    async startImageLibrary({state}) {
      if (!state.user) {
        // console.log("uer not logged in")
      }
      if (state.unsubscribeImageLibrary) {
        // console.log("ImageLibrary already subscribed");
        return;
      }
      const userId = state.user.uid;
      const collectionRef = collection(db, "images");
      const q = query(collectionRef,
          where("userId", "==", userId),
          orderBy("createdAt", "desc")
      );
      state.unsubscribeImageLibrary = onSnapshot(q, (querySnapshot) => {
        // console.log("ImageLibrary subscribed")
        let total = 0;
        const images = [];
        querySnapshot.forEach((doc) => {
          const img = doc.data();
          images.push(img);
          if (img.images?.length) {
            total += 1;
          }
        });
        state.imageLibrary = images;
        state.totalImages = total;
      }, (error) => {
        console.log("Error getting documents: ", error);
      });
    },
    async stopImageLibrary({state}) {
      if (state.unsubscribeImageLibrary) {
        state.unsubscribeImageLibrary();
        state.unsubscribeImageLibrary = null;
      }
    },
    async startUserSync({state}) {
      if (state.unsubscribeUser) {
        // console.log("user already subscribed");
        return;
      }
      const collectionRef = collection(db, "users");
      const q = query(collectionRef, where("email", "==", state.user.email));
      state.unsubscribeUser = onSnapshot(q, async (querySnapshot) => {
        if (!querySnapshot.empty) {
          // console.log("Document with the email exists");
          let user;
          querySnapshot.forEach((doc) => {
            user = doc.data();
          });
          state.userDetails = user;
          // console.log("state.userDetails", state.userDetails);
        }
      }, (error) => {
        console.log("Error getting documents: ", error);
      });
    },
    async checkout({ state, dispatch }, data) {
      if (!state.user) {
        try {
          await dispatch('loginWithGoogle');
          if (!state.user) {
            return null;
          }
        }
        catch (e) {
          console.error("error", e);
          return null;
        }
      }
      try  {
        const transactionData = {
          userId: state.user.uid,
          email: state.user.email,
          productId: data.productId,
          price: data.price,
          tokens: data.tokens,
          status: 'pending',
          createdAt: serverTimestamp()
        };
        const transactionDocRef = await addDoc(collection(db, "transactions"), transactionData)
        return transactionDocRef.id
      }
      catch (e) {
        console.error('Error:', e)
      }
      return null
    },
    async updateTransaction(state, data) {
      const transactionId = data.transactionId;
      const transactionDocRef = await doc(db, 'transactions', transactionId);
      const updateData = {
        status: data.status,
        updatedAt: serverTimestamp()
      }
      if (data.errorMessage) {
        updateData.errorMessage = data.errorMessage;
      }
      await updateDoc(transactionDocRef, updateData);
    },
    async loadShowcase({state, commit}) {
      if (state.showCase && state.showCase.length > 0) {
        return;
      }
      const showcaseRef = collection(db, "showcase");
      const showcaseSnapshot = await getDocs(showcaseRef);
      if (showcaseSnapshot.empty) {
        return;
      }
      const showcaseImages =
          showcaseSnapshot.docs.map((doc) => doc.data())
              .sort((a, b) => b.createdAt - a.createdAt);
      commit('setShowCase', showcaseImages);
    },
    async getAllImages() {
      console.log("Loading images...");
      const imgCollectionRef = collection(db, "images");
      // const q = query(imgCollectionRef,
      //     where("images", "!=", null),
      //     limit(10)
      // );
      const snapshot = await getDocs(imgCollectionRef);
      const images = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          imageId: doc.id,
          imageSrc: (data.images?.length > 0)? data.images[0] : "placeholder-image.jpeg",
          createdAt: data.createdAt,
          text: data.text,
          prompt: data.prompt,
          userId: data.userId,
          userName: '',
          userEmail: '',
        }
      });
      return images.sort((a, b) => b.createdAt - a.createdAt);
    },
    async addShowCase(state, image) {
      // Check if that image is already in showcase
      const showcaseRef = collection(db, "showcase");
      const showcaseQuery = query(showcaseRef, where("imageId", "==", image.imageId));
      const showCaseSnapshot = await getDocs(showcaseQuery);
      if (!showCaseSnapshot.empty) {
        await Swal.fire({
          icon: 'warning',
          title: 'Warning!!',
          text: 'Image already in showcase',
          timer: 5000
        });
        return;
      }
      // Get user details
      const userRef = collection(db, "users");
      const userQuery = query(userRef, where("uid", "==", image.userId));
      const userDoc = await getDocs(userQuery);
      if (userDoc.empty) {
        await Swal.fire({
          icon: 'warning',
          title: 'Warning!!',
          text: 'User not found',
          timer: 5000
        });
        return;
      }
      const userDetails = userDoc.docs[0].data();
      // Add to showcase
      await addDoc(showcaseRef, {
        imageId: image.imageId,
        imageSec: image.imageSrc,
        userId: image.userId,
        displayName: userDetails.displayName,
        prompt: image.prompt,
        createdAt: image.createdAt,
      });
      await Swal.fire({
        icon: 'success',
        title: 'Success!!',
        text: 'Image added to showcase',
        timer: 5000
      });
    },
    async removeShowCase(state, imageId) {
      const showcaseRef = collection(db, "showcase");
      const showcaseQuery = query(showcaseRef, where("imageId", "==", imageId));
      const snapshot = await getDocs(showcaseQuery);
      if (snapshot.empty) {
        await Swal.fire({
          icon: 'warning',
          title: 'Warning!!',
          text: 'Image not in showcase',
          timer: 5000
        });
        return;
      }
      if (snapshot.docs.length > 1) {
        await Swal.fire({
          icon: 'warning',
          title: 'Warning!!',
          text: 'Multiple docs of same ID',
          timer: 5000
        });
        return;
      }
      const docSnapshot = snapshot.docs[0];
      await deleteDoc(docSnapshot.ref);
      await Swal.fire({
        icon: 'success',
        title: 'Success!!',
        text: 'Image removed from showcase',
        timer: 5000
      });
    },
    async sendMessage(state, message) {
      const collectionRef = collection(db, "messages");
      addDoc(collectionRef, {
        name: message.name,
        email: message.email,
        message: message.message,
        createdAt: serverTimestamp()
      }).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Message sent!!',
          text: "Your message has been sent successfully! We'll contact you soon.",
          timer: 5000
        });
      });
    },
    async getThemes() {
      const colRef = collection(db, "themes");
      const docsRef = await getDocs(colRef);
      return docsRef.docs.map((doc) => doc.data())
          .sort((a, b) => a.id - b.id)
          .filter(v => v.isActive);
    },
    trackGenerateEvent() {
      window.fbq('trackCustom', "GenerateQRCode");
      window.gtag('event', 'generate_qr_code');
    },
    trackLoginEvent() {
      window.fbq('track', 'CompleteRegistration', { status: 'Login' });
      window.gtag('event', 'login', {
        'event_category': 'User',
        'event_label': 'Successful'
      });
    },
    trackPurchaseEvent(status, data) {
      window.gtag('event', 'purchase', {
        "transaction_id": data.transactionId,
        "affiliation": "YOUR_AFFILIATION",
        "value": data.value,
        "currency": data.currency,
        "items": [
          {
            "id": data.productId,
            "name": data.name,
            "category": "AI Image Generation Tokens",
            "quantity": 1,
            "price": data.value
          },
        ]
      });
    },
  },
  getters: {
    db() {
      return db;
    },
    auth() {
      return auth;
    },
    getGenerateData({generateData}) {
      return generateData;
    },
  }
});
