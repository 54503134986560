<template>
  <div id="app">
    <Header/>
    <div class="container">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import {onAuthStateChanged} from "firebase/auth";
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
  components: {Footer, Header},
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    ...mapMutations(['setUser']),
    ...mapActions(['startUserSync'])
  },
  created() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    // console.log("created");
    onAuthStateChanged(this.auth, (user) => {
      // console.log("onAuthStateChanged", user);
      if (user) {
        this.setUser(user)
        this.startUserSync()
        document.tidioIdentify = {
          distinct_id: user.uid,
          email: user.email,
          name: user.displayName,
          phone: user.phoneNumber
        };
        const tidioScript = document.createElement('script');
        tidioScript.src = '//code.tidio.co/5wdegc0adp7jhl33x0fgnw8yadb73qy3.js';
        document.body.appendChild(tidioScript);
      } else {
        this.setUser(null)
        document.tidioIdentify = {};
      }
    });
  }
}
</script>
