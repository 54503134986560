<template>
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <router-link to="/home" class="logo">
              <h1 class="logo-text mt-2 mt-lg-0">
                <span class="logo-qr">QR</span>CodeFox
                <span class="badge beta-badge">Beta</span>
              </h1>
            </router-link>
            <!-- ***** Logo End ***** -->

            <!-- ***** Menu Desktop Start ***** -->
            <ul class="nav d-none d-lg-block">
              <li>
                <router-link to="/home" :active-class="'active'">
                  <b-icon class="mr-2" icon="house-fill"/> Home
                </router-link>
              </li>
              <li>
                <router-link to="/generate" :active-class="'active'">
                  <b-icon class="mr-2" icon="gear-fill"/> Generate
                </router-link>
              </li>
              <li>
                <router-link to="/showcase" :active-class="'active'">
                  <b-icon class="mr-2" icon="image-fill"/> Showcase
                </router-link>
              </li>
<!--              <li><router-link to="/about" :active-class="'active'">About</router-link></li>-->
              <li v-if="user">
                <router-link to="/profile" :active-class="'active'">
                  Profile <ProfilePicture style="background: #1c1f20"/>
                </router-link>
              </li>
              <li v-else>
                <a href="#" @click="login">
                  <b-icon class="mr-2" icon="google"/> Sign in
                </a>
              </li>
            </ul>
            <!-- ***** Menu Desktop Start ***** -->

            <!-- ***** Menu Mobile Start ***** -->
            <ul class="nav d-lg-none" :class="{'d-block': isMenuOpen}" ref="menu">
              <li><router-link to="/home" :active-class="'active'">Home</router-link></li>
              <li><router-link to="/generate" :active-class="'active'">Generate</router-link></li>
              <li><router-link to="/showcase" :active-class="'active'">Showcase</router-link></li>
<!--              <li><router-link to="/about" :active-class="'active'">About</router-link></li>-->
              <li v-if="user">
                <router-link to="/profile" :active-class="'active'">
                  Profile <ProfilePicture style="background: #1c1f20"/>
                </router-link>
              </li>
              <li class="login-btn-li" v-else>
                <a href="#" class="login-btn fw-medium shadow-sm" @click="login">
                  <b-icon icon="google"/> Sign in
                </a>
              </li>
            </ul>
            <!-- ***** Menu Mobile Start ***** -->

            <span class='menu-trigger' @click="toggleMenu()">
              <span>Menu</span>
            </span>
            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState,  mapActions } from "vuex";
import ProfilePicture from "./ProfilePicture";

export default {
  name: "Header",
  data() {
    return {
      isMenuOpen: false
    }
  },
  components: {ProfilePicture},
  computed:  {
    ...mapState(['user'])
  },
  methods: {
    ...mapActions(['loginWithGoogle']),
    login() {
      this.loginWithGoogle()
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  },
  mounted() {
    const menu = this.$refs.menu;
    document.addEventListener('click', function(event) {
      if (event.target.className !== 'menu-trigger') {
        // console.log("close menu");
        menu.classList.remove('d-block');
      }
    });
  }
}
</script>

<style scoped lang="scss">
.header-area .main-nav .nav li:last-child:hover a, .header-area .main-nav .nav li:last-child a.active {
  color: #000000 !important;
  background: #ffffff !important;
}
.login-btn {
  color: #000000 !important;
  background: #ffffff !important;
  padding: 8px 16px 8px 8px !important;
  cursor: pointer;
  img {
    margin-left: 0;
    margin-right: 5px;
  }
}
.login-btn:hover {
  background: #000000 !important;
}
.logo-qr {
  color: rgba(255, 255, 255, 0.44);
}
.logo-text {
  color: #d3d3d3;
}
.beta-badge {
  color: var(--main-text-color);
  background: var(--main-color);
  font-size: 9px;
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
}
//@media (min-width: 768px) {
//  .logo {
//    min-width: 480px;
//  }
//}
</style>
