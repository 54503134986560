<template>
  <div class="main-profile px-0 py-3 px-md-4 py-md-5" @mouseenter="handleMouseEnter" ref="componentRef">
    <div class="row">
      <div class="col-lg-6 px-3">
        <div class="generate-form">
          <b-form @submit.prevent="submitForm">
            <div class="row mb-3 m-0">
              <div class="col-12 mb-4">
                <h1 class="mb-2">Looking for a custom solution for your brand?</h1>
                <p>Discover our personalized services to elevate your brand.</p>
              </div>
              <div class="col-12">
                <b-form-group class="mb-3" label="Name" label-for="name-input">
                  <b-form-input
                      id="name-input"
                      v-model="formData.name"
                      required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group class="mb-3" label="Email" label-for="email-input">
                  <b-form-input
                      id="email-input"
                      v-model="formData.email"
                      type="email"
                      required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group class="mb-4" label="Message" label-for="message-input">
                  <b-form-textarea
                      id="message-input"
                      v-model="formData.message"
                      rows="5"
                      required
                      ref="message"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-button class="submit-btn w-100" type="submit" variant="primary">Send</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
      <div class="d-none d-md-block col-md-6 px-4">
        <div class="row m-0 preview">
          <div class="col-md-6 m-0 p-0">
            <img class="img-fluid" height="512px" width="512px" :src="QRImg1" :alt="'AI-Powered QR code example 1'">
          </div>
          <div class="col-md-6 m-0 p-0">
            <img class="img-fluid" height="512px" width="512px" :src="QRImg4" :alt="'AI-Powered QR code example 4'">
          </div>
          <div class="col-md-6 m-0 p-0">
            <img class="img-fluid" height="512px" width="512px" :src="QRImg2" :alt="'AI-Powered QR code example 2'">
          </div>
          <div class="col-md-6 m-0 p-0">
            <img class="img-fluid" height="512px" width="512px" :src="QRImg3" :alt="'AI-Powered QR code example 3'">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRImg1 from "../assets/images/codes/QR-Code-1.webp";
import QRImg2 from "../assets/images/codes/QR-Code-2.webp";
import QRImg3 from "../assets/images/codes/QR-Code-3.webp";
import QRImg4 from "../assets/images/codes/QR-Code-4.webp";
import QRImg5 from "../assets/images/codes/QR-Code-5.webp";
import QRImg6 from "../assets/images/codes/QR-Code-6.webp";
import {mapActions, mapState} from "vuex";

export default {
  name: "Contact",
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      QRImg1: QRImg1,
      QRImg2: QRImg2,
      QRImg3: QRImg3,
      QRImg4: QRImg4,
      QRImg5: QRImg5,
      QRImg6: QRImg6,
    };
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    user(value) {
      this.formData.name = value?.displayName;
      this.formData.email = value?.email;
    }
  },
  methods: {
    ...mapActions(['sendMessage']),
    submitForm() {
      this.sendMessage(this.formData);
    },
    handleMouseEnter(){
      this.$refs.message.focus();
    },
  },
  mounted() {
    this.formData.name = this.user?.displayName;
    this.formData.email = this.user?.email;
  }
}
</script>

<style scoped>
.main-profile {
  padding: 70px 40px;
}
.preview {
  border-radius: 24px;
  overflow: hidden;
}
</style>
