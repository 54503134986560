<template>
  <footer class="mt-4">
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <h5 class="h1 mb-3 logo-text"><span>QR</span>CodeFox.com</h5>
          <p class="small mb-3">
            QRCodeFox.com is a user-friendly online tool for generating customized QR codes using AI. It allows you to easily create AI-Powered QR codes for various purposes, such as website URLs, contact information, text messages, and more. With QRCodeFox.com, you can customize QR codes according to your needs. It provides a quick and convenient way to generate QR codes for personal and business use.
          </p>
          <p class="small mb-3">
            <span class="fw-bold">Contact:</span><br>
            For business and journalist inquiries: <a class="text-primary" href="mailto:contact@vishalb.dev">contact@vishalb.dev</a>
          </p>
          <p class="small mb-0">Developed by <a class="text-primary" target="_blank" href="https://vishalb.dev">vishalb.dev</a></p>
        </div>
        <div class="col-lg-4 col-md-6">
          <h5 class=" mb-3">Use Cases</h5>
          <ul class="list-unstyled small">
            <li v-for="(item, index) in useCase" :key="index">
              <router-link :to="'/generate/' + item.link">Generate QR for {{item.title}}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-6">
          <h5 class=" mb-3">Quick links</h5>
          <ul class="list-unstyled small">
            <li><router-link to="/home">Hone</router-link></li>
            <li><router-link to="/generate">Generate</router-link></li>
            <li><router-link to="/showcase">Showcase</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data () {
    return {
      useCase: [
        {
          title: "E-Commerce",
          description: "Optimize e-commerce experiences with QR codes seamlessly integrated into product images, enabling quick access to product details, reviews, or purchasing options.",
          icon: "cart-fill",
          link: "e-commerce"
        },
        {
          title: "Online Advertising",
          description: "Enhance online ads with artistic QR codes, seamlessly integrating them into visuals to provide instant access to more information or promotional offers.",
          icon: "megaphone-fill",
          link: "online-advertising"
        },
        {
          title: "Business Cards",
          description: "Create impressive business cards with embedded QR codes, seamlessly blending contact information with stylish designs to leave a lasting impression.",
          icon: "person-lines-fill",
          link: "business-cards"
        },
        {
          title: "Educational Resources",
          description: "Make educational resources interactive by embedding QR codes within study materials, providing students with easy access to supplementary content or quizzes.",
          icon: "book-fill",
          link: "educational-resources"
        },
        {
          title: "Travel & Tourism",
          description: "Immerse travelers in destinations with QR codes integrated into travel visuals, offering access to travel guides, local recommendations, or virtual tours.",
          icon: "signpost2-fill",
          link: "travel-tourism"
        },
        {
          title: "Restaurant Menus",
          description: "Modernize restaurant menus with QR codes elegantly incorporated into the design, allowing diners to view menus, place orders, or access nutritional information.",
          icon: "file-text",
          link: "restaurant-menus"
        },
        {
          title: "Museum Exhibits",
          description: "Enrich museum exhibits with QR codes subtly embedded within artwork or display panels, offering visitors interactive experiences and detailed information.",
          icon: "bank2",
          link: "museum-exhibits"
        },
        {
          title: "Webinars & Online Courses",
          description: "Integrate QR codes into webinar or online course materials, allowing participants to effortlessly access additional resources or lecture materials.",
          icon: "play-btn-fill",
          link: "webinars-online-courses"
        },
        {
          title: "Corporate Events",
          description: "Elevate corporate events with QR codes discreetly embedded within event materials, providing attendees with event schedules, speaker bios, or networking profiles.",
          icon: "briefcase-fill",
          link: "corporate-events"
        },
        {
          title: "Music Album Covers",
          description: "Enhance music album covers with QR codes that blend seamlessly into the design, giving fans direct access to song previews, lyrics, or exclusive content.",
          icon: "music-note-beamed",
          link: "music-album-covers"
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  p {
    text-align: start;
    padding: 0;
    margin-bottom: 10px;
  }
  color: var(--text-color);
  text-align: start !important;
  .text-primary {
    color: var(--main-color) !important;
  }
  a:hover {
    color: var(--main-color) !important;
  }
  .logo-text {
    font-weight: 700;
    color: #d3d3d3;
    span {
      color: rgba(255, 255, 255, 0.44);
    }
  }
}
</style>
