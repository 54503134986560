import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const websiteTitle = 'QRCodeFox: AI-Powered QR code | ';

function checkFirebaseAuthUser() {
  for (let key in localStorage) {
    if (key.startsWith("firebase:authUser")) {
      return true;
    }
  }
  return false;
}

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: checkFirebaseAuthUser()? '/generate' : '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: websiteTitle + "Spice up your QR codes with a touch of creativity"
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: websiteTitle + "About"
    },
  },
  {
    path: '/generate',
    name: 'Generate',
    component: () => import('../views/GenerateV2.vue'),
    meta: {
      title: websiteTitle + "Generate"
    },
  },
  {
    path: '/v1/generate',
    name: 'GenerateV2',
    component: () => import('../views/Generate.vue'),
    meta: {
      title: websiteTitle + "GenerateV2"
    },
  },
  {
    path: '/generate/:type',
    name: 'Generate',
    component: () => import('../views/GenerateV2.vue'),
    meta: {
      title: websiteTitle + "Generate"
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      title: websiteTitle + "Profile"
    },
  },
  {
    path: '/showcase',
    name: 'Showcase',
    component: () => import('../views/Showcase.vue'),
    meta: {
      title: websiteTitle + "Showcase"
    },
  },
  {
    path: '/all-images',
    name: 'AllImages',
    component: () => import('../views/AllImages.vue'),
    meta: {
      title: websiteTitle + "AllImages"
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If there is a saved position, scroll to it
      return savedPosition;
    } else {
      // Scroll to the top of the page
      return { x: 0, y: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  const pageTitle = to.meta.title;
  if (pageTitle) {
    document.title = pageTitle;
  }
  next();
});

export default router
